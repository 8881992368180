<template>
  <!-- 新增抓拍记录 -->
  <div class="box-card" style="text-align: left;">
    <!-- 主要内容 -->
    <div style="overflow: auto; ">
      <!-- 查询表单 -->
      <el-form label-width="120px" ref="queryFormRef" class="demo-ruleForm" :model="queryForm" :rules="rules"
        @submit.native.prevent>
        <el-form-item label="计划名称：" prop="Name">
          <el-input maxlength="20" v-model="queryForm.Name" @input="(e) => (queryForm.Name = validSpace(e))"
            placeholder="请输入计划名称"></el-input>
        </el-form-item>
        <el-form-item label="周期：" prop="weekList">
          <el-select popper-class="my-select" v-model="queryForm.weekList" multiple collapse-tags placeholder="请选择周期">
            <el-option v-for="item in weekSelectList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间段：" prop="timeRange">
          <el-time-picker is-range v-model="queryForm.timeRange" range-separator="至" start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间" placeholder="请选择时间范围">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="时间间隔：" prop="CaptureRecordSecs">
          <el-select popper-class="my-select" v-model="queryForm.CaptureRecordSecs" placeholder="时间间隔">
            <el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备：" prop="MainId">
          <el-cascader popper-class="my-cascader" :options="deviceSelcetList" :props="props" collapse-tags clearable
            v-model="queryForm.MainId" placeholder="请选择设备"></el-cascader>
        </el-form-item>
      </el-form>
    </div>
    <!-- <lbButton type="goBack" icon="back" class="checkButton3" @click="defaultForm">返 回</lbButton> -->
    <!-- <el-button size="small" type="primary" @click="submitForm('query_form')" icon="iconfont">保存</el-button> -->
    <lbButton class="checkButton2" fill type="warning" icon="evasave" @click="submitForm(false)">仅保存</lbButton>
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitForm(true)">保存并开启</lbButton>
  </div>
</template>

<script>
import { capturePlanSceneDetail, createCapturePlan, updateCapturePlan, getCapturePlanDetail } from '@/api/monitoring'
export default {
  props: ['id'],
  data () {
    return {
      queryForm: {
        Name: '',
        weekList: [],
        timeRange: [new Date(2016, 9, 10, 0, 0, 0), new Date(2016, 9, 10, 23, 59, 59)],
        MainId: null,
        CaptureRecordSecs: null
      },
      rules: {
        Name: [{ required: true, message: '请输入计划名称', trigger: 'blur' }],
        weekList: [{ required: true, message: '请选择周期', trigger: 'change' }],
        timeRange: [{ required: true, message: '请选择日期', trigger: 'change' }],
        MainId: [{ required: true, message: '请选择设备', trigger: 'change' }],
        CaptureRecordSecs: [{ required: true, message: '请选择时间间隔', trigger: 'change' }]
      },
      isEdit: false,
      weekSelectList: [
        { value: 1, label: '周一' },
        { value: 2, label: '周二' },
        { value: 3, label: '周三' },
        { value: 4, label: '周四' },
        { value: 5, label: '周五' },
        { value: 6, label: '周六' },
        { value: 0, label: '周天' }],
      props: { multiple: true, value: 'Id', label: 'Name', children: 'Children' },
      deviceSelcetList: [],
      timeList: [
        { value: '1', label: '1分钟' },
        { value: '3', label: '3分钟' },
        { value: '5', label: '5分钟' },
        { value: '10', label: '10分钟' }]
    }
  },
  mounted () {
    // console.log("-------------id=", typeof (this.id - 0));
  },
  created () {
    this.isEdit = this.id !== '0'
    if (this.isEdit) {
      this.requestDevices({ CapturePlanId: this.id })
      this.initEdit()
    } else {
      this.requestDevices({ CapturePlanId: 0 })
    }
  },
  methods: {
    submitForm (flag) {
      var that = this
      this.$refs.queryFormRef.validate((valid) => {
        if (valid) {
          that.preparRequest(flag)
        } else {
          return false
        }
      })
    },
    resetForm () {
      this.$refs.queryFormRef.resetFields()
    },
    async requestDevices (params) {
      const res = await capturePlanSceneDetail(params)
      console.log('requestDevices----', res)
      if (res && res.Code === 200 && res.Data) {
        this.deviceSelcetList = res.Data.listResult
        this.queryForm.MainId = res.Data.listSelectVideo.map(item => [item.Id, item.MainId])
      }
    },
    preparRequest (flag) {
      var list = this.queryForm.MainId.map(item => item[1])
      var startTime = this.validDateTime(this.queryForm.timeRange[0])
      var endTime = this.validDateTime(this.queryForm.timeRange[1])
      const rangelist = this.queryForm.weekList.map(item => {
        return {
          WeekDay: item,
          StartTime: startTime,
          EndTime: endTime
        }
      })
      var obj = {
        Name: this.queryForm.Name,
        CaptureRecordSecs: this.queryForm.CaptureRecordSecs,
        MainId: list,
        TimeRangeList: rangelist,
        Enable: flag
      }
      if (this.isEdit) {
        this.editItem(obj)
      } else {
        this.addNewItem(obj)
      }
    },
    async addNewItem (v) {
      console.log('=======v=', JSON.stringify(v))
      const res = await createCapturePlan(v)
      if (res.Code === 200) this.$emit('closeDialog', false)
    },
    async editItem (v) {
      v.CapturePlanId = this.id
      console.log('=======v=', JSON.stringify(v))
      const res = await updateCapturePlan(v)
      if (res.Code === 200) this.$emit('closeDialog', false)
    },
    async initEdit () {
      var res = await getCapturePlanDetail({ CapturePlanId: this.id })
      if (res.Code === 200) {
        this.queryForm.Name = res.Data.data.Name
        var item = res.Data.TimeRangeList[0]
        this.queryForm.weekList = res.Data.TimeRangeList.map(k => k.WeekDay - 0)
        this.queryForm.timeRange = [new Date(item.StartTime.replace('T', ' ')), new Date(item.EndTime.replace('T', ' '))]
        this.queryForm.CaptureRecordSecs = res.Data.data.CaptureRecordSecs.toString()
        // this.queryForm.Enable = res.Data.data.Enable;
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }

}
</script>

<style lang="less" scoped>
.demo-ruleForm {

  .el-select,
  .el-date-editor,
  .el-cascader,
  .el-input {
    width: 98%;
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 140px;
  }

  .checkButton3 {
    position: absolute;
    right: 250px;
  }
}</style>
