<template>
  <!-- 图片抓拍 - 抓拍计划 -->
  <div style="margin-top:20px;">
      <!-- 查询表单 -->
      <el-form
          :inline="true"
          size="small"
          label-position="right"
          class="query_form"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="计划名称：">
            <el-input
              maxlength="20"
              v-model="queryForm.Name"
              @keyup.enter.native="onSubmit()"
              @input="(e) => (queryForm.Name = validSpace(e))"
              placeholder="请输入计划名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态：">
            <el-select
              popper-class="my-select"
              v-model="queryForm.EnableStatus"
              clearable
              placeholder="请选择状态"
              @change="onSubmit()"
            >
              <el-option label="待开启" :value="3"></el-option>
              <el-option label="已开启" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <lbButton icon="chaxun" :fill="true" @click="onSubmit()"
              >查询</lbButton
            >
          </el-form-item>
        </el-form>
    <div class="box-card" style="text-align: left">
      <le-card title="抓拍计划">
        <template slot="heard-right">
          <div class="right">
          <lbButton
            type="err"
            icon="shanchu1"
            @click="batchDel"
            v-if="isShowBtn('54bf31be74974ae4841eebbc')"
            >删除</lbButton
          >
          <lbButton
            icon="xinzeng"
            @click="addAndEdit('0')"
            v-if="
              this.getUserInfo.user_name != 'admin' &&
              isShowBtn('5c940fc31ef44919b7e79b78')
            "
            >新增</lbButton
          >
        </div>
        </template>
            <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
         <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
             <template slot-scope="{row}" slot="monitiorCount">
              <el-link
                type="primary"
                target="_blank"
                v-if="row.monitiorCount > 0"
                @click="openParking(row)"
                >{{ row.monitiorCount }}</el-link
              >
              <span v-else>{{ row.monitiorCount }}</span>
            </template>
             <template slot-scope="{row}" slot="Enable">
              <span v-if="row.Enable === 1" style="color: #67c23a">已开启</span>
              <span v-else style="color: #cccccc">待开启</span>
            </template>
             <template slot-scope="{row}" slot="create_time">
              {{
               row.create_time === "0001-01-01T00:00:00"
                  ? "/"
                  : $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss")
              }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton
                type="succes"
                icon="chakan"
                hint="查看"
                @click="lookDetail(row)"
              ></lbButton>
              <lbButton
                type="warning"
                icon="bianji"
                hint="编辑"
                @click="batchEdit(row)"
                v-if="
                  row.Enable != 1 && isShowBtn('d6b576fc54184308b3e7d1bb')
                "
              ></lbButton>
              <lbButton
                type="setting"
                icon="iconclose"
                hint="终止计划"
                @click="setStatus(row, false)"
                v-if="
                  row.Enable === 1 &&
                  isShowBtn('2ac841a516b148219325f5c6')
                "
              ></lbButton>
              <lbButton
                size="info"
                icon="chenggong1"
                hint="开启计划"
                @click="setStatus(row, true)"
                v-if="
                  row.Enable != 1 && isShowBtn('2ac841a516b148219325f5c6')
                "
              >
              </lbButton>
            </template>
            </avue-crud> -->
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="50"
            align="center"
            header-align="center"
          ></el-table-column>
          <el-table-column
            label="计划名称"
            min-width="150"
            prop="Name"
          ></el-table-column>
          <el-table-column label="视频监控设备">
            <template slot-scope="scope">
              <el-link
                type="primary"
                target="_blank"
                v-if="scope.row.monitiorCount > 0"
                @click="openParking(scope.row)"
                >{{ scope.row.monitiorCount }}</el-link
              >
              <span v-else>{{ scope.row.monitiorCount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.Enable === 1" style="color: #67c23a"
                >已开启</span
              >
              <span v-else style="color: #cccccc">待开启</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{
                scope.row.create_time === "0001-01-01T00:00:00"
                  ? "/"
                  : $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss")
              }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton
                type="succes"
                icon="chakan"
                hint="查看"
                @click="lookDetail(scope.row)"
              ></lbButton>
              <lbButton
                type="warning"
                icon="bianji"
                hint="编辑"
                @click="batchEdit(scope.row)"
                v-if="
                  scope.row.Enable != 1 && isShowBtn('d6b576fc54184308b3e7d1bb')
                "
              ></lbButton>
              <lbButton
                type="setting"
                icon="iconclose"
                hint="终止计划"
                @click="setStatus(scope.row, false)"
                v-if="
                  scope.row.Enable === 1 &&
                  isShowBtn('2ac841a516b148219325f5c6')
                "
              ></lbButton>
              <lbButton
                size="info"
                icon="chenggong1"
                hint="开启计划"
                @click="setStatus(scope.row, true)"
                v-if="
                  scope.row.Enable != 1 && isShowBtn('2ac841a516b148219325f5c6')
                "
              >
              </lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
        </le-card>
    </div>
    <lebo-dialog
      append-to-body
      title="视频监控设备"
      :isShow="dialogStuatus"
      width="30%" footerSlot
      @close="dialogStuatus=false" closeOnClickModal
    >
      <el-table
        :data="recorderDialogList"
        style="width: 100%"
        border
        stripe
        max-height="500"
        height="460"
      >
        <el-table-column
          header-align="center"
          align="center"
          type="index"
          label="序号"
          width="100"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          prop="name"
          label="设备名称"
        >
        </el-table-column>
      </el-table>
    </lebo-dialog>
    <!-- 查看计划详情 -->
    <lebo-dialog
      append-to-body
      title="查看计划"
      :isShow="isDetailDialog"
      width="30%" footerSlot @close="isDetailDialog=false" closeOnClickModal>
      <el-form label-width="120px" class="detailForm">
        <el-form-item label="计划名称：">{{ detail.Name }}</el-form-item>
        <el-form-item label="周期：">{{ detail.week }}</el-form-item>
        <el-form-item label="时间段：">{{ detail.time }}</el-form-item>
        <el-form-item label="时间间隔：">{{
          detail.CaptureRecordSecs
        }}</el-form-item>
        <el-form-item label="设备：">{{ detail.device }}</el-form-item>
        <el-form-item label="状态：">{{
          detail.Enable ? "已开启" : "待开启"
        }}</el-form-item>
      </el-form>
    </lebo-dialog>
     <!--编辑,新增抓拍计划 -->
     <lebo-dialog :title="dialogPictureCaptureRulesId=== '0'?'新增抓拍计划':'编辑抓拍计划'" width="30%" :isShow=" showAddPictureCaptureRules " :footerSlot=" true "
      @close=" showAddPictureCaptureRules = false ">
      <addPictureCaptureRules v-if="showAddPictureCaptureRules" :id="dialogPictureCaptureRulesId"
        @closeDialog="closePictureCaptureRules"></addPictureCaptureRules>
    </lebo-dialog>
  </div>
</template>
<script>
import {
  getCapturePlanListParam,
  setCapturePlanEnable,
  getCapturePlanDetail,
  deleteCapturePlan,
  getVideoChannelByCapturePlanId
} from '@/api/monitoring'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import addPictureCaptureRules from './components/addPictureCaptureRules/index.vue'
export default {
  components: { addPictureCaptureRules },
  data () {
    return {
      // 查询表单
      queryForm: {
        Name: '',
        EnableStatus: null,
        PageSize: 10,
        PageIndex: 1
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      dialogStuatus: false,
      recorderDialogList: [],
      weekSelectList: [
        { value: 1, label: '周一' },
        { value: 2, label: '周二' },
        { value: 3, label: '周三' },
        { value: 4, label: '周四' },
        { value: 5, label: '周五' },
        { value: 6, label: '周六' },
        { value: 0, label: '周天' }
      ],
      timeList: [
        { value: '1', label: '1分钟' },
        { value: '3', label: '3分钟' },
        { value: '5', label: '5分钟' },
        { value: '10', label: '10分钟' }
      ],
      isDetailDialog: false,
      detail: {},
      showAddPictureCaptureRules: false,
      dialogPictureCaptureRulesId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '计划名称',
            prop: 'Name'
          }, {
            label: '视频监控设备',
            prop: 'monitiorCount',
            slot: true
          }, {
            label: '状态',
            prop: 'Enable',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetCapturePlanList()
  },
  mounted () {
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['parkingList', 'queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getUserInfo'])
  },
  methods: {
    ...mapActions(['requestPorkingList', 'requestFindList']),
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fnGetCapturePlanList () {
      const res = await getCapturePlanListParam({
        Name: this.queryForm.Name,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        EnableStatus: this.queryForm.EnableStatus
          ? this.queryForm.EnableStatus
          : 1
      })
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetCapturePlanList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetCapturePlanList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetCapturePlanList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetCapturePlanList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetCapturePlanList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的抓拍计划, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // this.$msg.success('删除成功!');
            var obj = {}
            var arr = []
            for (var i = 0; i < this.multipleSelection.length; i++) {
              arr.push(this.multipleSelection[i].Id)
              if (arr.length === this.multipleSelection.length) {
                obj.CapturePlanIdList = arr
                this.fnDelTimeOrCount(obj)
              }
            }
          })
          .catch(() => {
            this.$msg.info('已取消删除')
          })
      } else {
        this.$msg.warning('请选择要删除的抓拍计划！')
      }
    },
    // 删除抓拍计划请求
    async fnDelTimeOrCount (obj) {
      const res = await deleteCapturePlan(obj)
      // console.log(res);
      // this.$msg.error(res.Message);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetCapturePlanList()
    },
    // 修改抓拍计划
    batchEdit (row) {
      this.addAndEdit(row.Id)
    },
    // 查看视频监控设备
    async openParking (item) {
      this.recorderDialogList = []
      const res = await getVideoChannelByCapturePlanId({
        CapturePlanId: item.Id
      })
      console.log('openParking----', res)
      if (res.Code === 200 && res.Data && res.Data.length > 0) {
        res.Data.forEach((item) => {
          this.recorderDialogList.push({
            name: item
          })
        })
        this.dialogStuatus = true
      } else {
        this.recorderDialogList = []
      }
      // console.log(this.recorderDialogList);
    },
    addAndEdit (v) {
      this.dialogPictureCaptureRulesId = v
      this.showAddPictureCaptureRules = true
      // this.$router.push(`/picture-capture-rules-edit/${v}`)
    },
    // 开启或终止计划
    setStatus (row, flag) {
      this.$confirm(flag ? '是否开启计划?' : '是否终止计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await setCapturePlanEnable({
            CapturePlanId: row.Id,
            Enable: flag
          })
          if (res && res.Code === 200) {
            this.$msg.success(res.Message)
            this.fnGetCapturePlanList()
          }
        })
        .catch(() => {})
    },
    async lookDetail (row) {
      // 获取计划详情
      var res = await getCapturePlanDetail({ CapturePlanId: row.Id })
      if (res && res.Code === 200) {
        this.detail = res.Data.data
        var item = res.Data.TimeRangeList[0]
        this.detail.week = res.Data.TimeRangeList.map(
          (k) =>
            this.weekSelectList.find((item) => item.value === Number(k.WeekDay))
              .label
        ).join('、')
        this.detail.time =
          this.$moment(item.StartTime).format('HH:mm:ss') +
          ' ~ ' +
          this.$moment(item.EndTime).format('HH:mm:ss')
        this.detail.CaptureRecordSecs = res.Data.data.CaptureRecordSecs
          ? this.timeList.find(
            (item) =>
              item.value === res.Data.data.CaptureRecordSecs.toString()
          ).label
          : null
      }
      const ret = await getVideoChannelByCapturePlanId({
        CapturePlanId: row.Id
      })
      console.log(ret.Data)
      this.detail.device =
        ret && ret.Code === 200 && ret.Data ? ret.Data.join('、') : ''
      this.isDetailDialog = true
    },
    closePictureCaptureRules () {
      this.showAddPictureCaptureRules = false
      this.fnGetCapturePlanList()
    }
  }
}
</script>

<style scoped lang="less">
.detailForm {
  /deep/ .el-form-item {
    margin-bottom: 0;
  }
}
</style>
